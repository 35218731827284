
import { defineComponent, ref, reactive, computed } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import store from "@/store";
import { translate, translateKey } from "@/hooks/TranslateService";

export default defineComponent({
  props: ["submitSurveyInput"],
  setup(props) {
    const surveyConfirmModalRef = ref<null | HTMLElement>(null);

    const warnings = reactive(computed(() => store.getters.getSurveyWarnings));

    const onShowModal = () => {
      showModal(surveyConfirmModalRef.value);
    };

    const closeModal = () => {
      hideModal(surveyConfirmModalRef.value);
    };

    const onSubmit = () => {
      props.submitSurveyInput();
      closeModal();
    };

    return {
      surveyConfirmModalRef,
      onShowModal,
      closeModal,
      warnings,
      translateKey,
      onSubmit,
      translate,
    };
  },
});
